export async function wrap(fut) {
  const resp = await fut
  if (typeof resp.data === 'object') {
    if (resp.data.status == 200) {
      return resp.data.data
    }
    throw new Error(`请求失败（${resp.data.status}）：${resp.data.msg}`)
  } else {
    throw new Error('返回值格式不正确')
  }
}
