import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import ViewUIPlus from "view-ui-plus";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "~bootstrap-icons/font/bootstrap-icons.css";
import PlotRender from "./components/plotRender/PlotRender.js";
//import "./index.css";

import "./styles/extra.less";
import "./styles/index.less";

import InputNumber from "@/components/common/iview/InputNumber.vue";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(router).use(pinia).use(ViewUIPlus).mount("#app");
app.component("InputNumber", InputNumber);
app.component("PlotRender", PlotRender);
